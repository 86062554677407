
import { Component, Vue } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import { Table as ElTable, TableColumn as ElTableColumn} from 'element-ui';
import VueSlider from 'vue-slider-component';
import { Splide, SplideSlide} from '@splidejs/vue-splide';
import LandingFooter from '@/components/Footer/LandingFooter.vue';
// @ts-ignore
import ultraIcon from '@/assets/images/NFX-Ultra.svg';
// @ts-ignore
import scpIcon from '@/assets/images/crypto-index-30.svg';
// @ts-ignore
import lockerIcon from '@/assets/images/NFX-locker.svg';
// @ts-ignore
import tethmakerIcon from '@/assets/images/NCT-Tethmaker.svg';
// @ts-ignore
import coinsmakerIcon from '@/assets/images/NCT-Coinsmaker.svg';
// @ts-ignore
import vpsIcon from '@/assets/images/upgrade-vps-icon.svg';


@Component({
  components: {
    Bar,
    ElTable,
    ElTableColumn,
    VueSlider,
    Splide,
    SplideSlide,
    LandingFooter,
  },
})
export default class LandingApi extends Vue {
  public mainLanding = true;
  public theme = 'theme--white';
  public cookieBlock = false;
  public product = 'scp30';
  public showModal = false;
  public quantity = 1;
  public productInfo = {
    scp30: {
      title: 'API key for system SCP 30',
      short_title: 'SCP 30',
      price: 299,
      icon: scpIcon,
    },
    ultra: {
      title: 'API key for system NFX Ultra',
      short_title: 'NFX Ultra',
      price: 121,
      icon: ultraIcon,
    },
    locker: {
      title: 'API key for system NFX Locker',
      short_title: 'NFX Locker',
      price: 121,
      icon: lockerIcon,
    },
    tethmaker: {
      title: 'API key for system NCT T-maker',
      short_title: 'NCT T-maker',
      price: 121,
      icon: tethmakerIcon,
    },
    coinsmaker: {
      title: 'API key for system NCT C-maker',
      short_title: 'NCT C-maker',
      price: 121,
      icon: coinsmakerIcon,
    },
    vps1m: {
      title: 'VPS for 1 month',
      short_title: 'VPS 1 month',
      price: 3,
      icon: vpsIcon,
    },
    vps3m: {
      title: 'VPS for 3 month',
      short_title: 'VPS 3 month',
      price: 9,
      icon: vpsIcon,
    },
    vps6m: {
      title: 'VPS for 6 month',
      short_title: 'VPS 6 month',
      price: 18,
      icon: vpsIcon,
    },
    vps12m: {
      title: 'VPS for 12 month',
      short_title: 'VPS 12 month',
      price: 36,
      icon: vpsIcon,
    },
  };

  public cookieAccept() {
    // @ts-ignore
    this.$cookie.set('cookie_accept', true, 1);
    this.cookieBlock = false;
  }
  public mounted() {
    // @ts-ignore
    if (!this.$cookie.get('cookie_accept')) {
      this.cookieBlock = true;
    }
    if (window.location.host === 'apikey.nl') {
      this.mainLanding = false;
    }
  }
  public signLink() {
    window.location.href = this.$router.currentRoute.params.promoCode ?
        `https://my.apikey.nl/register?promocode=${this.$router.currentRoute.params.promoCode}&client=true` : `https://my.apikey.nl/register?client=true`;
  }

}

