
import {Component, Vue} from 'vue-property-decorator';
import LandingApi from './LandingApi.vue';
import LandingDark from './LandingDark.vue';
import LandingStatic from './LandingStatic.vue';
import {dispatchGetAllConfigs} from '@/store/main/actions';

@Component({
  components: {
    LandingApi,
    LandingDark,
    LandingStatic,
  },
})
export default class LandingBase extends Vue {
  public apiDomain = false;

  public async created() {
    await dispatchGetAllConfigs(this.$store);
    if (window.location.host === 'apikey.nl' || window.location.host === 'localhost:8080') {
      this.apiDomain = true;
    }
  }
}
