
import {Component, Vue} from 'vue-property-decorator';

import '@/assets/landing/css/jquery.mCustomScrollbar.css';
import '@/assets/landing/css/grid.css';
import '@/assets/landing/css/style.css';
import '@/assets/landing/js/libs.min.js';
import {readAppConfigs} from '@/store/main/getters';
import {dispatchGetAllConfigs} from '@/store/main/actions';
//  import '@/assets/landing/js/jquery.spincrement.min.js';
// import '@/assets/landing/js/common.js';
// import '@/assets/landing/js/particles.min.js';
// import '@/assets/landing/js/particlesRun.js';

@Component
export default class LandingStatic extends Vue {
  public showMobile = false;
  public domain = 'nitrex';

  get appConfigs() {
    return readAppConfigs(this.$store);
  }

  public async mounted() {
    await dispatchGetAllConfigs(this.$store);
    if (this.$router.currentRoute.params.promoCode === '0000003') {
      console.log(this.appConfigs);
      console.log(this.appConfigs.cabinet_domain_url);
    }
    this.domain = window.location.host;
  }
}
