
import { Component, Vue } from 'vue-property-decorator';
import { Bar } from 'vue-chartjs';
import { Table as ElTable, TableColumn as ElTableColumn} from 'element-ui';
import VueSlider from 'vue-slider-component';
import { Splide, SplideSlide} from '@splidejs/vue-splide';
import {dispatchGetLandingRobotPrice} from '@/store/robots/actions';
import {readSupportFaq} from '@/store/support/getters';
import {dispatchGetFAQs} from '@/store/support/actions';
import LandingFooter from '@/components/Footer/LandingFooter.vue';

@Component({
  components: {
    Bar,
    ElTable,
    ElTableColumn,
    VueSlider,
    Splide,
    SplideSlide,
    LandingFooter,
  },
})
export default class LandingDark extends Vue {
  public mainLanding = true;
  public name = 'light-table';
  public theme = 'theme--dark';

  public blockBtn = false;
  public tradeLimit = 200.00;
  public promoCode = '';
  public robotPrice = '0.0';
  public sliderGradient = {
    value: this.tradeLimit,
    max: 100000,
    min: 200,
  };

  public text = `
      Lorem ipsum, dolor sit amet consectetur adipisicing elit.
      Nobis, sit autem aspernatur rem in nihil ducimus odit
      excepturi tempora architecto optio, cumque, vel magnam
      earum reiciendis maxime sint voluptates labore? Explicabo,
      iusto rem!
  `;
  public optionsbBuyPopularSystems = {
    rewind : true,
    width : '100vw',
    gap : '15px',
    fixedWidth: '358px',
    height: '500px',
    focus: 'center',
    trimSpace: false,
    start: 1,
  };
  public optionsReviews = {
    rewind : true,
    width : '100vw',
    gap : '15px',
    fixedWidth: '490px',
    height: '80%',
    focus: 'center',
    trimSpace: false,
    start: 1,
  };

  public async created() {
    await dispatchGetFAQs(this.$store, {slug: 'landing'});
    await this.handleChangeTradeLimit(this.tradeLimit);
    if (this.$router.currentRoute.params.promoCode) {
      this.promoCode = this.$router.currentRoute.params.promoCode;
    }
    if (window.location.host === 'apikey.nl') {
      this.mainLanding = false;
    }
  }

  public changeTheme() {
    this.theme = this.theme === '' ? 'light-theme' : '';
  }

  get faqs() {
    return readSupportFaq(this.$store);
  }

  public checkTradeLimit(value) {
    const minValue = this.sliderGradient.min;
    if (value < minValue) {
      this.tradeLimit = minValue;
    } else {
      this.tradeLimit = value;
    }
  }

  public async handleChangeTradeLimit(event) {
    this.blockBtn = true;
    if (Number(event)) {
      this.checkTradeLimit(Number(event));
    }
    const data = {
      deposit_trade: this.tradeLimit,
      currency: 'EUR',
      promocode: this.promoCode,
    };
    const response = await dispatchGetLandingRobotPrice(this.$store, data);
    this.robotPrice = Number(response.price).toFixed(2);
    this.blockBtn = false;
  }

  public handleSetTradeLimitBtn(newLimit) {
    this.handleChangeTradeLimit(newLimit);
    // @ts-ignore
    this.$refs.buyLink.click();
  }

  public handleBuySystemBtn() {
    let url = 'https://new.nitrex.pro/register';
    if (this.tradeLimit >= this.sliderGradient.min) {
      url = `${url}?preferred_trade_limit=${this.tradeLimit.toFixed(0)}`;
    }
    if (this.promoCode !== '') {
      url = `${url}${url.includes('?') ? '&' : '?' }promocode=${this.promoCode}`;
    }
    window.location.href = url;
  }

}

